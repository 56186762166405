.about-page {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.store-history,
.mission,
.team {
    margin: 20px 0;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

h2 {
    margin-bottom: 20px;
    color: #084ba9;
}

.team-member {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.team-member img {
    width: 100px;
    height: auto;
    margin-right: 20px;
    border-radius: 50%;
}

p {
    line-height: 1.5;
    font-size: 16px;
}
