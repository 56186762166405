.navigation {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 16px;
}

.navigation a {
    color: #ffdf59;
    text-decoration: none;
    margin-left: 20px;
    margin-bottom: 200px;
    transition: color 0.3s;
}

.navigation a:hover {
    color: #fff;
}
