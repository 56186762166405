@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400&display=swap');

/* Add this media query for mobile devices */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
    }

    .store-info,
    .social-media,
    .legal {
        /*margin-bottom: 20px;*/
    }
}


footer {
    background-color: #222;
    color: #fff;
    padding: 20px 25px;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.store-info,
.social-media {
    margin-bottom: 10px;
}

.social-media a {
    color: #fff;
    margin-right: 10px;
    text-decoration: none;
    font-size: 32px; /* Add this line to increase the icon size */
}

.social-media a:hover {
    color: #ccc;
}

.legal {
    margin-top: 20px;
}
