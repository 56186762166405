header {
    /*height: 80px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #084ba9;
    position: sticky; /* Add this line */
    top: 0; /* Add this line */
    /*left: 0; !* Add this line *!*/
    /*width: 100%; !* Add this line *!*/
    z-index: 1000; /* Add this line */
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-container img {
    width: 36px;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s; /* Add a transition effect for the hover effect */
}

.logo-container a:hover img {
    transform: scale(1.1); /* Add a slight hover effect for the logo */
}

.store-name {
    color: #ffdf59;
    margin-left: 20px;
    font-size: 24px;
    font-weight: 600; /* Increase the font weight for the store name */
}

.navigation {
    display: flex;
    align-items: center;
    list-style: none;
}

.navigation a {
    color: #ffdf59;
    text-decoration: none;
    margin-left: 20px;
    transition: color 0.3s;
}

.navigation a:hover {
    color: #fff;
}

/* Add position: relative to the hamburger-menu */
.hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 16px;
    cursor: pointer;
    position: relative; /* Add this line */
    /*z-index: 1001; !* Add this line *!*/
}

.navigation-mobile {
    display: none;
    position: absolute;
    top: 100%;
    right: 0; /* Change left to right */
    width: 100px; /* Set a fixed width for the mobile navigation */
    background-color: #fff;
    flex-direction: column;
    border-radius: 6px;
    padding: 15px 25px;
    /*border-top: 1px solid #ffdf59;*/
    list-style: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a box shadow */
    z-index: 1; /* Add a z-index to ensure it's above other elements */
}


.hamburger-menu span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ffdf59;
    transition: all 0.3s;
}

.navigation-mobile a {
    color: #084ba9;
    text-decoration: none;
    margin-bottom: 10px;
    transition: color 0.3s;
    font-size: 20px;
}

.navigation-mobile a:hover {
    color: #fff;
}

@media (max-width: 768px) {
    .logo-container img {
        width: 48px;
        height: auto;
    }

    .store-name {
        font-size: 24px;
    }

    .navigation {
        display: none; /* Hide the navigation on mobile by default */
    }

    .hamburger-menu {
        display: flex; /* Show the hamburger menu on mobile screens */
    }

    .navigation-mobile {
        display: flex; /* Show the mobile navigation when the menu is open */
    }
}
