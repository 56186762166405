.contact-page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    /*background-color: #f5f5f5;*/
    font-family: Arial, sans-serif;
}

.contact-info,
.map {
    margin: 20px 0;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.map-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

@media (min-width: 768px) {
    .contact-page {
        flex-direction: row;
    }

    .contact-info,
    .map {
        flex: 1;
        margin: 0 20px;
    }
}

h2 {
    margin-bottom: 20px;
    color: #084ba9;
}

p {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 16px;
}

.fa-phone-alt,
.fa-envelope {
    margin-right: 5px;
}
