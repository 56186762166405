.home-page {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    /*background-color: #2a6ca8;*/
    /*background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");*/
}

.hero-section img {
    width: 100%;
    height: auto;
    border-radius: 5px; /* Add rounded corners to the image */
}

.store-description,
.promotions {
    margin: 20px 0;
    padding: 20px;
    background-color: #f5f5f5; /* Add a background color for each section */
    border-radius: 5px; /* Add rounded corners to each section */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

h2, h3 {
    font-size: 24px; /* Increase the font size for the headings */
    font-weight: bold; /* Make the headings bold */
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
}

p {
    line-height: 1.5; /* Improve readability by increasing line height */
    margin-bottom: 10px;
}
